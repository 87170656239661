'use client'

import CursorCustom from '~@Atoms/cursor-custom/cursor-custom'
import LinkItem from '~@Atoms/link-item/link-item'
import BlockPicture from '~@Atoms/block-picture/block-picture'
import BlockHeading from '~@Molecules/block-heading/block-heading'

import { ISectionMap } from '~@Types/components/ISectionMap'
import useCheckMobile from '~@Hooks/useCheckMobile'

const SectionMap = ({
  link,
  surtitle,
  title,
  list,
  locale = 'fr',
}: ISectionMap) => {
  const hasLink = !!link?.href

  const isMobile = useCheckMobile()

  return (
    <LinkItem
      {...link}
      className={`SectionMap relative block w-full rounded-t-2xl bg-brand-primary text-white-white ${!isMobile ? 'cursor-none' : ''} focus:outline-black-black focus:outline-2 focus:outline-offset-2`}
    >
      <div className="SectionMap-container container px-lg py-5xl tablet:px-3xl tablet:py-8xl desktop:grid desktop:grid-cols-12 desktop:gap-y-4xl desktop:gap-x-3xl">
        <BlockHeading
          className="desktop:col-start-2 desktop:col-end-12"
          isReversed={true}
          surtitle={surtitle}
          title={title}
        />
        <ul className="SectionMap-list desktop:col-start-2 desktop:col-end-5 typeface-heading-a5-standard">
          {list.map((item, index) => (
            <li
              key={`${item}-${index}`}
              className="SectionMap-item relative pb-xl mb-xl after:absolute after:bottom-none after:left-none after:block after:content[''] after:w-full after:h-4xs after:bg-white-20"
            >
              {item}
            </li>
          ))}
        </ul>
        <div className="desktop:col-start-5 py-xl tablet:py-3xl desktop:py-none desktop:col-end-12">
          <BlockPicture
            className="w-full scale-125 desktop:scale-100"
            src={'/img/section-map/map.svg'}
            alt={title}
            width={763}
            height={385}
          />
        </div>

        {hasLink && !isMobile && (
          <CursorCustom
            cursorSize={142}
            picture={
              locale === 'en'
                ? '/img/section-map/cursor-nav-map-en.svg'
                : '/img/section-map/cursor-nav-map.svg'
            }
            centerOnMap={true}
          />
        )}
      </div>
    </LinkItem>
  )
}

export default SectionMap
