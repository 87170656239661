'use client'
import { useEffect, useRef, useState } from 'react'
import { IKeyfigure } from '~@Types/components/IKeyfigure'

const Keyfigure = ({ keyfigure, unit, label }: IKeyfigure) => {
  const [count, setCount] = useState<number>(0)
  const scrollKeyfigureRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleScroll = () => {
      if (typeof window !== 'undefined' && scrollKeyfigureRef.current) {
        const scrollPosition = window.scrollY
        const elementOffset = scrollKeyfigureRef.current.offsetTop

        const screenHeight = window.innerHeight
        const timing = keyfigure > 99 ? 5 : 50

        if (scrollPosition > elementOffset - screenHeight + 50) {
          const interval = setInterval(() => {
            setCount((prevCount) => {
              if (prevCount < keyfigure) {
                return prevCount + 1
              } else {
                clearInterval(interval)
                return keyfigure
              }
            })
          }, timing)
        }
      }
    }

    if (typeof window !== 'undefined') {
      handleScroll()

      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [keyfigure])

  return (
    <p
      ref={scrollKeyfigureRef}
      className="BlockKeyfigure flex flex-col justify-start items-start content-start h-full gap-y-xs text-brand-primary"
    >
      <span className="typeface-heading-a1-standard">
        {count}
        {unit}
      </span>
      <span className="BlockKeyfigure-label typeface-subtitle1-standard">
        {label}
      </span>
    </p>
  )
}

export default Keyfigure
