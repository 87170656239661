'use client'

import { twMerge } from 'tailwind-merge'
import Image from 'next/image'
import BubbleShape from './bubble-shape'
import LinkItem from '~@Atoms/link-item/link-item'
import CursorCustom from '~@Atoms/cursor-custom/cursor-custom'

import { TextureType } from '~@Enum/TextureType'

import { ITeaserCorporate } from '~@Types/components/ITeaserCorporate'
import useCheckMobile from '~@Hooks/useCheckMobile'

const TeaserCorporate = ({
  className,
  link,
  surtitle,
  title,
  type = TextureType.ROCK,
}: ITeaserCorporate) => {
  const hasLink = link.href !== ''
  const isMobile = useCheckMobile()

  return (
    <LinkItem
      {...link}
      className={twMerge(
        'TeaserCorporate block group relative pr-2xl overflow-hidden desktop:pr-4xl',
        !isMobile ? 'cursor-none' : '',
        className
      )}
    >
      <div
        className="relative z-20 ml-[40%] bg-white-white w-[60%] h-2xl rounded-tl-2xl rounded-tr-2xl desktop:h-4xl"
        aria-hidden="true"
      >
        <div className="absolute bottom-none left-none transform -translate-x-full w-2xl h-2xl overflow-hidden after:content-[''] after:absolute after:top-none after:left-none after:w-2xl after:h-2xl after:rounded-br-xl after:shadow-[0_0_0_20px_theme(colors.white-white)] desktop:w-4xl desktop:h-4xl desktop:after:w-4xl desktop:after:h-4xl"></div>
      </div>
      <div className="TeaserCorporate-wrapper relative z-10 text-brand-primary bg-white-white rounded-2xl rounded-tr-none pl-lg pr-md pt-xl pb-5xl desktop:pl-2xl desktop:pr-5xl desktop:pb-9xl desktop:pt-3xl">
        <p className="TeaserCorporate-surtitle flex items-center gap-x-xs typeface-subtitle1-standard mb-lg before:content-[''] before:block before:w-xs before:h-xs before:rounded-full before:bg-brand-primary">
          {surtitle}
        </p>
        <h2 className="TeaserCorporate-surtitle typeface-heading-a4-standard bg-gradient-text transition-all duration-500 group-hover:[-webkit-text-fill-color:inherit] group-hover:text-brand-primary hyphens-auto">
          {title}
        </h2>
      </div>
      <div
        className="TeaserCorporate-image absolute z-0 bottom-none right-none w-[184px] h-full pt-4xl desktop:pt-7xl"
        aria-hidden="true"
      >
        <Image
          className={`TeaserCorporate-bg w-full h-full rounded-2xl rounded-tl-none`}
          src={`/img/teaser-corporate/theme-${type}.jpg`}
          alt={type}
          width={184}
          height={405}
        />
      </div>
      <div
        className="absolute z-30 bottom-lg right-none w-[167px] h-[67px] desktop:w-[389px] desktop:h-[100px] transform translate-x-full opacity-0 group-hover:translate-x-none group-hover:opacity-100 transition-transform duration-500"
        aria-hidden="true"
      >
        <BubbleShape className="w-full h-full" />
      </div>
      {hasLink && !isMobile && <CursorCustom />}
    </LinkItem>
  )
}

export default TeaserCorporate
