import { IClassName } from '~@Types/components/IClassName'

export default function SecuritySymbol({ className }: IClassName) {
  return (
    <svg
      className={className}
      width="216"
      height="216"
      viewBox="0 0 216 216"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M145.028 58.6285C148.437 58.6285 151.2 55.8655 151.2 52.4571C151.2 49.0487 148.437 46.2856 145.028 46.2856C141.62 46.2856 138.857 49.0487 138.857 52.4571C138.857 55.8655 141.62 58.6285 145.028 58.6285Z"
        fill="url(#paint0_linear_6851_1096)"
      />
      <path
        d="M145.028 83.3142C148.437 83.3142 151.2 80.5512 151.2 77.1428C151.2 73.7344 148.437 70.9714 145.028 70.9714C141.62 70.9714 138.857 73.7344 138.857 77.1428C138.857 80.5512 141.62 83.3142 145.028 83.3142Z"
        fill="url(#paint1_linear_6851_1096)"
      />
      <path
        d="M145.028 108C148.437 108 151.2 105.237 151.2 101.828C151.2 98.4201 148.437 95.6571 145.028 95.6571C141.62 95.6571 138.857 98.4201 138.857 101.828C138.857 105.237 141.62 108 145.028 108Z"
        fill="url(#paint2_linear_6851_1096)"
      />
      <path
        d="M145.028 132.686C148.437 132.686 151.2 129.923 151.2 126.514C151.2 123.106 148.437 120.343 145.028 120.343C141.62 120.343 138.857 123.106 138.857 126.514C138.857 129.923 141.62 132.686 145.028 132.686Z"
        fill="url(#paint3_linear_6851_1096)"
      />
      <path
        d="M126.514 151.2C129.922 151.2 132.686 148.437 132.686 145.028C132.686 141.62 129.922 138.857 126.514 138.857C123.106 138.857 120.343 141.62 120.343 145.028C120.343 148.437 123.106 151.2 126.514 151.2Z"
        fill="url(#paint4_linear_6851_1096)"
      />
      <path
        d="M108 169.714C111.408 169.714 114.171 166.951 114.171 163.543C114.171 160.134 111.408 157.371 108 157.371C104.591 157.371 101.828 160.134 101.828 163.543C101.828 166.951 104.591 169.714 108 169.714Z"
        fill="url(#paint5_linear_6851_1096)"
      />
      <path
        d="M89.4855 151.2C92.8939 151.2 95.6569 148.437 95.6569 145.028C95.6569 141.62 92.8939 138.857 89.4855 138.857C86.0771 138.857 83.3141 141.62 83.3141 145.028C83.3141 148.437 86.0771 151.2 89.4855 151.2Z"
        fill="url(#paint6_linear_6851_1096)"
      />
      <path
        d="M95.6569 58.6285C99.0653 58.6285 101.828 55.8655 101.828 52.4571C101.828 49.0487 99.0653 46.2856 95.6569 46.2856C92.2486 46.2856 89.4855 49.0487 89.4855 52.4571C89.4855 55.8655 92.2486 58.6285 95.6569 58.6285Z"
        fill="url(#paint7_linear_6851_1096)"
      />
      <path
        d="M120.343 58.6285C123.751 58.6285 126.514 55.8655 126.514 52.4571C126.514 49.0487 123.751 46.2856 120.343 46.2856C116.934 46.2856 114.171 49.0487 114.171 52.4571C114.171 55.8655 116.934 58.6285 120.343 58.6285Z"
        fill="url(#paint8_linear_6851_1096)"
      />
      <path
        d="M70.9712 58.6285C74.3796 58.6285 77.1427 55.8655 77.1427 52.4571C77.1427 49.0487 74.3796 46.2856 70.9712 46.2856C67.5628 46.2856 64.7998 49.0487 64.7998 52.4571C64.7998 55.8655 67.5628 58.6285 70.9712 58.6285Z"
        fill="url(#paint9_linear_6851_1096)"
      />
      <path
        d="M70.9712 108C74.3796 108 77.1427 105.237 77.1427 101.828C77.1427 98.4201 74.3796 95.6571 70.9712 95.6571C67.5628 95.6571 64.7998 98.4201 64.7998 101.828C64.7998 105.237 67.5628 108 70.9712 108Z"
        fill="url(#paint10_linear_6851_1096)"
      />
      <path
        d="M70.9712 83.3142C74.3796 83.3142 77.1427 80.5512 77.1427 77.1428C77.1427 73.7344 74.3796 70.9714 70.9712 70.9714C67.5628 70.9714 64.7998 73.7344 64.7998 77.1428C64.7998 80.5512 67.5628 83.3142 70.9712 83.3142Z"
        fill="url(#paint11_linear_6851_1096)"
      />
      <path
        d="M70.9712 132.686C74.3796 132.686 77.1427 129.923 77.1427 126.514C77.1427 123.106 74.3796 120.343 70.9712 120.343C67.5628 120.343 64.7998 123.106 64.7998 126.514C64.7998 129.923 67.5628 132.686 70.9712 132.686Z"
        fill="url(#paint12_linear_6851_1096)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6851_1096"
          x1="64.7998"
          y1="108"
          x2="151.2"
          y2="108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D003D" />
          <stop offset="0.476296" stopColor="#E92184" />
          <stop offset="1" stopColor="#1F9E82" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6851_1096"
          x1="64.7998"
          y1="108"
          x2="151.2"
          y2="108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D003D" />
          <stop offset="0.476296" stopColor="#E92184" />
          <stop offset="1" stopColor="#1F9E82" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6851_1096"
          x1="64.7998"
          y1="108"
          x2="151.2"
          y2="108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D003D" />
          <stop offset="0.476296" stopColor="#E92184" />
          <stop offset="1" stopColor="#1F9E82" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_6851_1096"
          x1="64.7998"
          y1="108"
          x2="151.2"
          y2="108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D003D" />
          <stop offset="0.476296" stopColor="#E92184" />
          <stop offset="1" stopColor="#1F9E82" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_6851_1096"
          x1="64.7998"
          y1="108"
          x2="151.2"
          y2="108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D003D" />
          <stop offset="0.476296" stopColor="#E92184" />
          <stop offset="1" stopColor="#1F9E82" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_6851_1096"
          x1="64.7998"
          y1="108"
          x2="151.2"
          y2="108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D003D" />
          <stop offset="0.476296" stopColor="#E92184" />
          <stop offset="1" stopColor="#1F9E82" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_6851_1096"
          x1="64.7998"
          y1="108"
          x2="151.2"
          y2="108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D003D" />
          <stop offset="0.476296" stopColor="#E92184" />
          <stop offset="1" stopColor="#1F9E82" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_6851_1096"
          x1="64.7998"
          y1="108"
          x2="151.2"
          y2="108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D003D" />
          <stop offset="0.476296" stopColor="#E92184" />
          <stop offset="1" stopColor="#1F9E82" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_6851_1096"
          x1="64.7998"
          y1="108"
          x2="151.2"
          y2="108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D003D" />
          <stop offset="0.476296" stopColor="#E92184" />
          <stop offset="1" stopColor="#1F9E82" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_6851_1096"
          x1="64.7998"
          y1="108"
          x2="151.2"
          y2="108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D003D" />
          <stop offset="0.476296" stopColor="#E92184" />
          <stop offset="1" stopColor="#1F9E82" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_6851_1096"
          x1="64.7998"
          y1="108"
          x2="151.2"
          y2="108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D003D" />
          <stop offset="0.476296" stopColor="#E92184" />
          <stop offset="1" stopColor="#1F9E82" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_6851_1096"
          x1="64.7998"
          y1="108"
          x2="151.2"
          y2="108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D003D" />
          <stop offset="0.476296" stopColor="#E92184" />
          <stop offset="1" stopColor="#1F9E82" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_6851_1096"
          x1="64.7998"
          y1="108"
          x2="151.2"
          y2="108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D003D" />
          <stop offset="0.476296" stopColor="#E92184" />
          <stop offset="1" stopColor="#1F9E82" />
        </linearGradient>
      </defs>
    </svg>
  )
}
