'use client'

import ButtonSpecial from '~@Atoms/button-special/button-special'
import TeaserCorporate from '~@Molecules/teaser-corporate/teaser-corporate'

import { TextureType } from '~@Enum/TextureType'

import { ISectionCorporate } from '~@Types/components/ITeaserCorporate'

import useShowMore from '~@Hooks/handleShowMore'
import useCheckMobile from '~@Hooks/useCheckMobile'

const SectionCorporate = ({ list, buttonLabel }: ISectionCorporate) => {
  const { showAll, isButtonVisible, handleShowMore, fourthItemRef } =
    useShowMore()
  const isMobile = useCheckMobile()

  const getTextureType = (index: number): TextureType => {
    const types = [
      TextureType.ROCK,
      TextureType.WOOD,
      TextureType.COAL,
      TextureType.MARBLE,
    ]
    return types[index % types.length]
  }

  return (
    <div className="SectionCorporate">
      <ul
        className={`SectionCorporate-list ${list.length > 2 && !showAll ? 'pt-4xl pb-xl' : 'py-4xl'} tablet:grid tablet:grid-cols-8 tablet:gap-x-3xl desktop:grid-cols-12 desktop:px-none desktop:py-4xl`}
      >
        {list.map((corpo, index) => {
          const shouldHide = isMobile && index > 2 && !showAll

          if (shouldHide) {
            return null
          }

          return (
            <li
              ref={index === 3 ? fourthItemRef : null}
              key={index}
              className={`SectionCorporate-item mx-auto max-w-[300px] desktop:max-w-none tablet:col-span-4 desktop:col-span-6 desktop-large:col-span-5 mt-lg ${
                index % 2 !== 0
                  ? 'tablet:mt-5xl desktop:col-start-7'
                  : 'desktop:col-start-1 desktop-large:col-start-2'
              } transition-opacity duration-500 ease-in-out`}
            >
              <TeaserCorporate
                className="w-full"
                type={getTextureType(index)}
                link={corpo.link}
                surtitle={corpo.surtitle}
                title={corpo.title}
              />
            </li>
          )
        })}
      </ul>
      {list.length > 3 && !showAll && (
        <div
          className={`SectionCorporate-action flex justify-center desktop:hidden transition-opacity duration-500 ${isButtonVisible ? 'opacity-100' : 'opacity-0'} p-lg pb-4xl`}
        >
          <ButtonSpecial
            className="cursor-pointer"
            label={buttonLabel}
            isReversed
            link={{
              tagElt: 'button',
              onClick: handleShowMore,
            }}
          />
        </div>
      )}
    </div>
  )
}

export default SectionCorporate
