'use client'

import { twMerge } from 'tailwind-merge'

import LinkItem from '~@Atoms/link-item/link-item'
import CursorCustom from '~@Atoms/cursor-custom/cursor-custom'
import useCheckMobile from '~@Hooks/useCheckMobile'

import ExpertiseSymbol from './expertise-symbol'
import SynergySymbol from './synergy-symbol'
import VisionarySymbol from './visionary-symbol'
import SecuritySymbol from './security-symbol'

import { ITeaserHighlight } from '~@Types/components/IHighlight'

export default function TeaserHighlight({
  innerClassName,
  title,
  description,
  link,
  type,
  isAlternate,
}: ITeaserHighlight) {
  const ariaLabel = title
  const pictureType = (type) => {
    switch (type) {
      case 'expertise':
        return <ExpertiseSymbol className={typeClass} aria-label={ariaLabel} />
      case 'synergy':
        return <SynergySymbol className={typeClass} aria-label={ariaLabel} />
      case 'visionary':
        return <VisionarySymbol className={typeClass} aria-label={ariaLabel} />
      case 'security':
        return <SecuritySymbol className={typeClass} aria-label={ariaLabel} />
      default:
        return <ExpertiseSymbol className={typeClass} aria-label={ariaLabel} />
    }
  }

  const hasLink = !!link?.href

  const typeClass = isAlternate ? 'w-[80px]' : 'w-[80px] desktop:w-[216px]'

  const borderClass = 'border-grey-light border-solid border-[1px]'

  const isMobile = useCheckMobile()

  return (
    <LinkItem
      className={`TeaserHighlight block relative pt-2xl box-border desktop:pt-4xl ${hasLink && !isMobile ? 'cursor-none' : ''}`}
      {...link}
    >
      <div
        className={twMerge(
          `TeaserHighlight-wrapper relative pt-lg px-lg pb-3xl bg-white-white border-grey-light border-solid border-[1px] rounded-2xl box-border desktop:pt-3xl desktop:pt-4xl desktop:pb-8xl ${!isAlternate && 'desktop:pr-[216px]'} desktop:grid desktop:grid-cols-10 desktop:gap-xl`,
          innerClassName
        )}
      >
        <div className="TeaserHighlight-header text-brand-primary flex items-center gap-md mb-lg desktop:gap-xl desktop:mb-none desktop:col-start-1 desktop:col-end-4">
          <h3 className="TeaserHighlight-title typeface-heading-a5-standard pr-4xl desktop:pr-none">
            {title}
          </h3>
        </div>
        <p
          className={`TeaserHighlight-description typeface-body1-standard desktop:col-start-5 desktop:col-end-10`}
        >
          {description}
        </p>
        <div
          className={`TeaserHighlight-symbol absolute top-none right-none transform -translate-y-1/2 translate-x-[1px] w-[80px] h-[80px] flex items-center justify-center rounded-t-2xl ${borderClass} border-b-[0px] border-l-[0px] bg-white-white ${!isAlternate && 'desktop:w-[260px] desktop:h-[260px] desktop:p-2xl'} desktop:-translate-y-4xl`}
        >
          <div
            aria-hidden="true"
            className={`absolute top-[20px] right-[78px] w-[20px] h-[20px] bg-white-white z-10 desktop:h-4xl desktop:-top-[1px] ${!isAlternate && 'desktop:right-[258px]'}`}
          >
            <div
              className={`w-full h-full rounded-br-2xl ${borderClass} border-l-[0px] border-t-[0px] box-border`}
            ></div>
          </div>
          <div
            aria-hidden="true"
            className={`absolute top-none right-[79px] w-[20px] h-[20px] bg-white-white z-10 transform translate-x-full ${!isAlternate && 'desktop:right-[259px]'}`}
          >
            <div
              className={`w-full h-full rounded-tl-2xl ${borderClass} border-r-[0px] border-b-[0px] box-border`}
            ></div>
          </div>
          {pictureType(type)}
        </div>
      </div>
      {hasLink && !isMobile && <CursorCustom />}
    </LinkItem>
  )
}
