'use client'

import React, {
  createContext,
  useCallback,
  useState,
  useEffect,
  use,
} from 'react'
import useEmblaCarousel, {
  type UseEmblaCarouselType,
} from 'embla-carousel-react'
import { ChevronLeft, ChevronRight } from 'lucide-react'

import { cn } from '~@Utils/shadcnHelper'
import { Button } from '~@Lib/button'

type CarouselAlternateApi = UseEmblaCarouselType[1]
type UseCarouselAlternateParameters = Parameters<typeof useEmblaCarousel>
type CarouselAlternateOptions = UseCarouselAlternateParameters[0]
type CarouselAlternatePlugin = UseCarouselAlternateParameters[1]

type CarouselAlternateProps = {
  opts?: CarouselAlternateOptions
  plugins?: CarouselAlternatePlugin
  orientation?: 'horizontal' | 'vertical'
  setApi?: (api: CarouselAlternateApi) => void
}

type CarouselAlternateContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0]
  api: ReturnType<typeof useEmblaCarousel>[1]
  scrollPrev: () => void
  scrollNext: () => void
  canScrollPrev: boolean
  canScrollNext: boolean
} & CarouselAlternateProps

const CarouselAlternateContext =
  createContext<CarouselAlternateContextProps | null>(null)

function useCarouselAlternate() {
  const context = use(CarouselAlternateContext)

  if (!context) {
    throw new Error(
      'useCarouselAlternate must be used within a <CarouselAlternate />'
    )
  }

  return context
}

const CarouselAlternate = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CarouselAlternateProps
>(
  (
    {
      orientation = 'horizontal',
      opts,
      setApi,
      plugins,
      className,
      children,
      ...props
    },
    ref
  ) => {
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        axis: orientation === 'horizontal' ? 'x' : 'y',
      },
      plugins
    )
    const [canScrollPrev, setCanScrollPrev] = useState(false)
    const [canScrollNext, setCanScrollNext] = useState(false)

    const onSelect = useCallback((api: CarouselAlternateApi) => {
      if (!api) {
        return
      }

      setCanScrollPrev(api.canScrollPrev())
      setCanScrollNext(api.canScrollNext())
    }, [])

    const scrollPrev = useCallback(() => {
      api?.scrollPrev()
    }, [api])

    const scrollNext = useCallback(() => {
      api?.scrollNext()
    }, [api])

    const handleKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'ArrowLeft') {
          event.preventDefault()
          scrollPrev()
        } else if (event.key === 'ArrowRight') {
          event.preventDefault()
          scrollNext()
        }
      },
      [scrollPrev, scrollNext]
    )

    useEffect(() => {
      if (!api || !setApi) {
        return
      }

      setApi(api)
    }, [api, setApi])

    useEffect(() => {
      if (!api) {
        return
      }

      onSelect(api)
      api.on('reInit', onSelect)
      api.on('select', onSelect)

      return () => {
        api?.off('select', onSelect)
      }
    }, [api, onSelect])

    return (
      <CarouselAlternateContext.Provider
        value={{
          carouselRef,
          api: api,
          opts,
          orientation:
            orientation || (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
        }}
      >
        <div
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          className={cn('relative', className)}
          role="region"
          aria-roledescription="carousel"
          {...props}
        >
          {children}
        </div>
      </CarouselAlternateContext.Provider>
    )
  }
)
CarouselAlternate.displayName = 'CarouselAlternate'

const CarouselAlternateContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { carouselRef, orientation } = useCarouselAlternate()

  return (
    <div ref={carouselRef}>
      <div
        ref={ref}
        className={cn(
          'flex',
          orientation === 'horizontal'
            ? 'flex align-center gap-x-3xl will-change-transform'
            : '',
          className
        )}
        {...props}
      />
    </div>
  )
})
CarouselAlternateContent.displayName = 'CarouselAlternateContent'

const CarouselAlternateItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { orientation } = useCarouselAlternate()

  return (
    <div
      ref={ref}
      role="group"
      aria-roledescription="slide"
      className={cn(
        'min-w-0 shrink-0 grow-0 basis-full desktop-large:flex-[0_0_45%]',
        orientation === 'horizontal' ? '' : '',
        className
      )}
      {...props}
    />
  )
})
CarouselAlternateItem.displayName = 'CarouselAlternateItem'

const CarouselAlternatePrevious = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button>
>(({ className, variant = 'outline', size = 'icon', ...props }, ref) => {
  const { orientation, scrollPrev, canScrollPrev } = useCarouselAlternate()

  return (
    <Button
      ref={ref}
      variant={variant}
      // size={size}
      className={cn(
        'group rounded-full p-sm transition-background',
        orientation === 'horizontal' ? '' : '',
        canScrollPrev ? 'bg-grey-semi hover:bg-brand-primary' : 'bg-grey-light',
        className
      )}
      disabled={!canScrollPrev}
      onClick={scrollPrev}
      {...props}
    >
      <ChevronLeft
        className={cn(
          'h-large w-large transition-color',
          orientation === 'horizontal' ? '' : '',
          canScrollPrev
            ? 'text-brand-primary group-hover:text-white-white'
            : 'text-grey-grey',
          className
        )}
      />
      <span className="sr-only">Previous slide</span>
    </Button>
  )
})
CarouselAlternatePrevious.displayName = 'CarouselAlternatePrevious'

const CarouselAlternateNext = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button>
>(({ className, variant = 'outline', size = 'icon', ...props }, ref) => {
  const { orientation, scrollNext, canScrollNext } = useCarouselAlternate()

  return (
    <Button
      ref={ref}
      variant={variant}
      // size={size}
      className={cn(
        'group rounded-full p-sm transition-all',
        orientation === 'horizontal' ? '' : '',
        canScrollNext ? 'bg-grey-semi hover:bg-brand-primary' : 'bg-grey-light',
        className
      )}
      disabled={!canScrollNext}
      onClick={scrollNext}
      {...props}
    >
      <ChevronRight
        className={cn(
          'h-large w-large transition-color',
          orientation === 'horizontal' ? '' : '',
          canScrollNext
            ? 'text-brand-primary group-hover:text-white-white'
            : 'text-grey-grey',
          className
        )}
      />
      <span className="sr-only">Next slide</span>
    </Button>
  )
})
CarouselAlternateNext.displayName = 'CarouselAlternateNext'

export {
  type CarouselAlternateApi,
  CarouselAlternate,
  CarouselAlternateContent,
  CarouselAlternateItem,
  CarouselAlternatePrevious,
  CarouselAlternateNext,
}
