import { IEditorialWysiwyg } from '~@Types/components/IEditorialWysiwyg'
import './EditorialWysiwyg.scss'

const EditorialWysiwyg = ({ className = '', wysiwyg }: IEditorialWysiwyg) => {
  const sanitizedHtml =
    typeof wysiwyg === 'string' ? wysiwyg.trim().replace(/\s+/g, ' ') : ''

  return (
    <div
      className={`EditorialWysiwyg ${className}`.trim()}
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
    />
  )
}

export default EditorialWysiwyg
