'use client'

import { IScrollingBaseline } from '~@Types/components/IScrollingBaseline'
import { useEffect, useRef, useState } from 'react'

const ScrollingBaseline = ({ text }: IScrollingBaseline) => {
  const baselineRef = useRef<HTMLSpanElement | null>(null)
  const [widthContainer, setWidthContainer] = useState<number>(0)

  const addWidthContainer = () => {
    if (baselineRef.current) {
      const windowWidth = window.innerWidth
      const widthBaseline = baselineRef.current.offsetWidth
      setWidthContainer(
        windowWidth < widthBaseline ? widthBaseline : windowWidth
      )
    }
  }

  useEffect(() => {
    addWidthContainer()
    window.addEventListener('resize', addWidthContainer)

    return () => {
      window.removeEventListener('resize', addWidthContainer)
    }
  }, [])

  return (
    <div className="overflow-hidden my-5xl desktop-large:my-8xl">
      <div style={widthContainer ? { width: `${widthContainer}px` } : {}}>
        <p className="ScrollingBaseline-text text-brand-primary desktop-large:text-[120px] typeface-heading-a0-standard w-full flex flex-row animate-scroll-x">
          <span ref={baselineRef} className="block whitespace-nowrap pr-lg">
            {text}
          </span>
          <span className="block whitespace-nowrap pr-lg">{text}</span>
          <span className="block whitespace-nowrap">{text}</span>
        </p>
        <p className="ScrollingBaseline-text text-brand-primary desktop-large:text-[120px] typeface-heading-a0-standard w-full flex flex-row animate-scroll-x-reversed">
          <span className="block whitespace-nowrap pr-lg">{text}</span>
          <span className="block whitespace-nowrap pr-lg">{text}</span>
          <span className="block whitespace-nowrap">{text}</span>
        </p>
      </div>
    </div>
  )
}

export default ScrollingBaseline
