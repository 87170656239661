'use client'

import { useEffect, useRef, useState } from 'react'
import { IModalWindow } from '~@Types/components/IModalWindow'
import { X } from '@phosphor-icons/react/dist/ssr'
import { createPortal } from 'react-dom'
import EditorialWysiwyg from '~@Components/organisms/editorial-wysiwyg/editorial-wysiwyg'

export default function ModalWindow({
  id,
  title,
  wysiwyg,
  children,
  popinState,
  onClose,
  adaptedHeight,
}: Readonly<IModalWindow>) {
  const [isClient, setIsClient] = useState(false)
  const modalWindowRef = useRef<HTMLDivElement | null>(null)
  const hasAddedClasses = useRef({ preventScroll: false, overlayBlur: false })

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      modalWindowRef.current &&
      !modalWindowRef.current.contains(event.target as Node) &&
      popinState
    ) {
      onClose()
    }
  }

  const handleEscapeKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose()
    }
  }

  const toggleBodyClass = (className: string, shouldAdd: boolean) => {
    const { body } = document
    if (shouldAdd && !body.classList.contains(className)) {
      body.classList.add(className)
      hasAddedClasses.current = {
        ...hasAddedClasses.current,
        [className]: true,
      }
    } else if (!shouldAdd && hasAddedClasses.current[className]) {
      body.classList.remove(className)
    }
  }

  useEffect(() => {
    setIsClient(true)
  }, [])

  useEffect(() => {
    if (popinState) {
      toggleBodyClass('PreventFromScroll', true)
      toggleBodyClass('OverlayBlur', true)
      document.addEventListener('keydown', handleEscapeKey)
      document.addEventListener('click', handleOutsideClick)
    } else {
      toggleBodyClass('PreventFromScroll', false)
      toggleBodyClass('OverlayBlur', false)
    }

    return () => {
      toggleBodyClass('PreventFromScroll', false)
      toggleBodyClass('OverlayBlur', false)
      document.removeEventListener('keydown', handleEscapeKey)
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [popinState, handleEscapeKey, handleOutsideClick])

  if (!isClient) return null

  return (
    <div>
      {createPortal(
        <dialog
          id={id}
          aria-modal={popinState}
          {...(title ? { 'aria-labelledby': 'ModalWindow-title' } : {})}
          className={`ModalWindow bg-[transparent] fixed top-none left-none w-screen h-screen justify-center flex items-center z-[600] ${
            popinState
              ? 'opacity-100 transition-opacity duration-250 ease-in pointer-events-auto'
              : 'opacity-0 pointer-events-none'
          }`}
        >
          <div
            ref={modalWindowRef}
            className={`relative max-w-[71%] w-full ${adaptedHeight ? null : 'h-[77%]'} z-[11] shadow-2xl rounded-3xl`}
          >
            <div
              className={`overflow-hidden w-full h-full rounded-3xl ${title ? 'bg-white-white p-lg desktop-large:p-3xl' : null}`}
            >
              {popinState && title && (
                <p
                  className="typeface-heading-a2-standard mb-xl text-center text-brand-primary"
                  id="ModalWindow-title"
                >
                  <span className="bg-gradient-text">{title}</span>
                </p>
              )}
              {popinState && wysiwyg && <EditorialWysiwyg wysiwyg={wysiwyg} />}
              {popinState && children && (
                <div className="w-full h-full">{children}</div>
              )}
              <button
                onClick={onClose}
                className="absolute border-[1px] border-white-white/25 shadow-[0px_0px_5px_0px_rgba(0,0,0,0.10),_0px_5px_25px_-5px_rgba(0,0,0,0.10)] bottom-full left-full rounded-full text-white-white bg-brand-primary p-sm transition-all duration-300 hover:rotate-90 hover:text-brand-primary hover:bg-white-white focus:outline-black-black focus:outline-2 focus:outline-offset-2"
              >
                <X size={24} />
                <span className="sr-only">Close</span>
              </button>
            </div>
          </div>
        </dialog>,
        document.body
      )}
    </div>
  )
}
