'use client'

import BlockPicture from '~@Atoms/block-picture/block-picture'
import { IVideoButton } from '~@Types/components/IVideoButton'
import { Play } from '@phosphor-icons/react/dist/ssr'
import picture from '../../../../public/img/video-button/video-button.jpg'
import ModalWindow from '../modal-window/modal-window'
import { useState, useEffect } from 'react'

export default function VideoButton({ label, code }: IVideoButton) {
  const [isDisplayed, setIsDisplayed] = useState(false)

  useEffect(() => {}, [isDisplayed])

  return (
    <div>
      <button
        onClick={() => setIsDisplayed(true)}
        className="group flex absolute bottom-3xl right-3xl items-center typeface-subtitle1-special rounded-full bg-white-white text-black-black p-xs text-left"
      >
        <div
          className="relative left-[0] z-10 transition-[left] duration-500 group-hover:left-[calc(100%-64px)]"
          aria-hidden="true"
        >
          <BlockPicture
            src={picture}
            alt="player video"
            width="64"
            height="64"
            className="rounded-full brightness-[0.85]"
          />
          <Play
            size={18}
            color="#ffffff"
            weight="fill"
            className="absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-1/2"
          />
        </div>
        <span className="group-hover:-translate-x-[64px] w-[145px] ml-sm pr-sm leading-none transition-all duration-500">
          {label}
        </span>
      </button>
      <ModalWindow
        popinState={isDisplayed}
        onClose={() => setIsDisplayed(false)}
      >
        <>
          <iframe
            className="w-full h-full bg-black-black"
            src={`https://www.youtube.com/embed/${code}?rel=0`}
            title="YouTube video player"
            allowFullScreen
          />
        </>
      </ModalWindow>
    </div>
  )
}
