'use client'

import { useEffect, useRef } from 'react'

import BlockPicture from '~@Atoms/block-picture/block-picture'
import ButtonPrimary from '~@Atoms/button-primary/button-primary'
import VideoButton from '~@Atoms/video-button/video-button'

import { ISectionHero } from '~@Types/components/ISectionHero'

export default function SectionHero({
  overTitle,
  title,
  link,
  videoButton,
  picture,
}: ISectionHero) {
  const titleRef = useRef<HTMLHeadingElement | null>(null)
  const buttonRef = useRef<HTMLParagraphElement | null>(null)

  const calculateLineWidths = (numLines: number): number[] => {
    const startingWidth = 55
    const decrement = startingWidth / (numLines + 1)
    const widths: number[] = []

    for (let i = 0; i < numLines; i++) {
      widths.push(startingWidth - i * decrement)
    }

    return widths
  }

  const calculateSplitText = (text: string, lineWidths: number[]): string[] => {
    const words = text.split(' ')
    const lines: string[] = []
    let currentLine = ''

    lineWidths.forEach((width) => {
      const lineWidthInChars = Math.floor((width / 100) * text.length)
      while (
        words.length > 0 &&
        currentLine.length + words[0].length + 1 <= lineWidthInChars
      ) {
        currentLine += (currentLine.length > 0 ? ' ' : '') + words.shift()
      }
      if (currentLine.trim() !== '') {
        lines.push(currentLine)
      }
      currentLine = ''
    })

    if (words.length > 0) {
      lines.push(words.join(' '))
    }

    return lines
  }

  useEffect(() => {
    if (title && titleRef.current) {
      const numLines = Math.ceil(title.length / 10)
      const lineWidths = calculateLineWidths(numLines)
      const lines = calculateSplitText(title, lineWidths)

      const formattedText = lines
        .map((line, index) => {
          const isLastLine = index === lines.length - 1
          const isFirstLine = index === 0
          const baseWidth = (buttonRef?.current?.offsetWidth ?? 0) + 48
          const spanWidth = isFirstLine ? baseWidth + 48 : baseWidth
          const extraStructure = isFirstLine
            ? ''
            : `<span class="${isLastLine ? 'hidden desktop:block' : 'block'} h-[32px] w-[32px] -top-4xs right-4xs translate-x-full absolute overflow-hidden before:block before:content[''] before:h-[33px] before:w-[33px] before:rounded-tl-[60%] before:shadow-[0_0_0_32px_white]" aria-hidden="true"></span>`
          return `
            <span ${buttonRef?.current ? `style="min-width: ${spanWidth}px"` : ''}" class="bg-white-white pr-lg ${isFirstLine ? 'pr-3xl' : ''} ${isLastLine || isFirstLine ? 'rounded-br-xl' : ''} desktop:rounded-br-xl pb-xs inline-block relative -mt-4xs desktop-large:pl-lg">
              <span class="bg-gradient-text text-[25px] leading-none desktop:text-[50px] desktop-max:text-[67px]">
                ${line}
              </span>
              ${extraStructure}
            </span>`
        })
        .join('')

      titleRef.current.innerHTML = formattedText
    }
  }, [title])

  return (
    <div className="SectionHero relative">
      {picture ? (
        <div className="SectionHero-picture desktop-large:before:content-[''] desktop-large:before:absolute desktop-large:before:top-none desktop-large:before:left-none desktop-large:before:h-[100px] desktop-large:before:bg-white-white desktop-large:before:w-[30%] relative">
          <BlockPicture {...picture} className="w-full rounded-xl" />
          <span
            className="desktop-large:h-[32px] desktop-large:w-[32px] desktop-large:top-[100px] desktop-large:left-none desktop-large:absolute desktop-large:block desktop-large:overflow-hidden desktop-large:before:block table:before:content[''] desktop-large:before:h-[32px] desktop-large:before:w-[32px] desktop-large:before:rounded-tl-[60%] desktop-large:before:shadow-[0_0_0_32px_white]"
            aria-hidden="true"
          ></span>
          <span
            className="desktop-large:h-[32px] desktop-large:w-[32px] desktop-large:top-[100px] desktop-large:left-9xl desktop-large:translate-x-[-32px] desktop-large:absolute desktop-large:block desktop-large:overflow-hidden desktop-large:before:block desktop-large:before:content[''] desktop-large:before:h-[32px] desktop-large:before:w-[32px] desktop-large:before:rounded-tr-[60%] desktop-large:before:shadow-[0_0_0_32px_white]"
            aria-hidden="true"
          ></span>
        </div>
      ) : null}
      <div className="SectionHero-content absolute z-10 -left-4xs desktop-large:left-9xl -top-4xs">
        {overTitle && (
          <p className="SectionHero-overTitle text-brand-primary typeface-subtitle1-standard relative bg-white-white pb-md pr-md desktop-large:pl-md -mt-4xs">
            • {overTitle}
            <span
              className="h-[32px] w-[32px] top-none right-4xs translate-x-full absolute block overflow-hidden before:block before:content[''] before:h-[33px] before:w-[33px] before:rounded-tl-[60%] before:shadow-[0_0_0_32px_white]"
              aria-hidden="true"
            ></span>
          </p>
        )}
        <h1
          className="SectionHero-title typeface-heading-a2-standard flex flex-col items-start"
          ref={titleRef}
        >
          {title}
        </h1>
        {link && (
          <p>
            <span
              ref={buttonRef}
              className="h-[64px] pr-md desktop-large:pl-md pb-md pt-xs bg-white-white relative inline-block rounded-br-xl -mt-4xs desktop-large:rounded-b-xl"
            >
              <ButtonPrimary {...link} />
              <span
                className="h-[32px] w-[32px] -top-4xs right-4xs translate-x-full absolute block overflow-hidden before:block before:content[''] before:h-[33px] before:w-[33px] before:rounded-tl-[60%] before:shadow-[0_0_0_32px_white]"
                aria-hidden="true"
              ></span>
              <span
                className="h-[32px] w-[32px] bottom-4xs translate-y-full -left-4xs absolute block overflow-hidden before:block before:content[''] before:h-[33px] before:w-[33px] before:rounded-tl-[60%] before:shadow-[0_0_0_32px_white] desktop-large:hidden"
                aria-hidden="true"
              ></span>
            </span>
          </p>
        )}
      </div>

      {!!videoButton?.code && (
        <>
          <svg
            aria-hidden="true"
            className="absolute z-[1] right-[0] bottom-[124px]"
            xmlns="http://www.w3.org/2000/svg"
            width="314"
            height="67"
            fill="none"
          >
            <path
              fill="#d11b74"
              d="M107 63h12.16c.45-.9.7-1.91.7-2.98 0-3.69-3-6.65-6.69-6.65h-.2c-3.68 0-6.67 2.99-6.67 6.67 0 1.07.26 2.07.7 2.96Z"
            />
            <path
              fill="url(#b)"
              d="M126.54 40.02c3.68 0 6.66-3 6.65-6.69 0-3.69-3-6.65-6.69-6.65h-.2c-3.69 0-6.67 2.99-6.67 6.67 0 3.69 2.99 6.67 6.67 6.67h.24Z"
            />
            <path
              fill="url(#c)"
              d="M33.55 53.37h-.2c-3.69 0-6.67 2.99-6.67 6.67 0 3.69 2.99 6.67 6.67 6.67h.23c3.68 0 6.66-3 6.65-6.69 0-3.69-3-6.65-6.69-6.65h.01Z"
            />
            <path
              fill="url(#d)"
              d="M133.68 63h12.16c.45-.9.7-1.91.7-2.98 0-3.69-3-6.65-6.69-6.65h-.2c-3.68 0-6.67 2.99-6.67 6.67 0 1.07.26 2.07.7 2.96Z"
            />
            <path
              fill="url(#e)"
              d="M60.24 53.37h-.2c-3.69 0-6.67 2.99-6.67 6.67 0 3.69 2.99 6.67 6.67 6.67h.23c3.68 0 6.66-3 6.65-6.69 0-3.69-3-6.65-6.69-6.65h.01Z"
            />
            <path
              fill="url(#f)"
              d="M6.87 53.37h-.2C2.98 53.37 0 56.36 0 60.04c0 3.69 2.99 6.67 6.67 6.67h.23c3.68 0 6.66-3 6.65-6.69 0-3.69-3-6.65-6.69-6.65h.01Z"
            />
            <path
              fill="url(#g)"
              d="M80.32 63h12.16c.45-.9.7-1.91.7-2.98 0-3.69-3-6.65-6.69-6.65h-.2c-3.69 0-6.67 2.99-6.67 6.67 0 1.07.26 2.07.7 2.96Z"
            />
            <path
              fill="url(#h)"
              d="M99.86 40.02c3.68 0 6.66-3 6.65-6.69 0-3.69-3-6.65-6.69-6.65h-.2c-3.69 0-6.67 2.99-6.67 6.67 0 3.69 2.99 6.67 6.67 6.67h.24Z"
            />
            <path
              fill="url(#i)"
              d="M153.22 40.02c3.68 0 6.66-3 6.65-6.69 0-3.69-3-6.65-6.69-6.65h-.2c-3.69 0-6.67 2.99-6.67 6.67 0 3.69 2.98 6.67 6.67 6.67h.24Z"
            />
            <path
              fill="url(#j)"
              d="M193.47 13.34c3.68 0 6.66-3 6.65-6.69 0-3.69-3-6.65-6.69-6.65h-.2c-3.69 0-6.67 2.99-6.67 6.67 0 3.69 2.99 6.67 6.67 6.67h.24Z"
            />
            <path
              fill="url(#k)"
              d="M173.22 33.35c0 3.69 2.99 6.67 6.67 6.67h.23c3.68 0 6.66-3 6.65-6.69 0-3.69-3-6.65-6.69-6.65h-.2c-3.68 0-6.67 2.99-6.67 6.67h.01Z"
            />
            <path
              fill="url(#l)"
              d="M193.44 53.37h-.2c-3.69 0-6.67 2.99-6.67 6.67 0 1.07.26 2.07.7 2.96h12.16c.45-.9.7-1.91.7-2.98 0-3.69-3-6.65-6.69-6.65Z"
            />
            <path
              fill="url(#m)"
              d="M172.74 63c.45-.9.7-1.91.7-2.98 0-3.69-3-6.65-6.69-6.65h-.2c-3.69 0-6.67 2.99-6.67 6.67 0 1.07.26 2.07.7 2.96h12.16Z"
            />
            <path
              fill="url(#n)"
              d="M166.78 13.34c3.68 0 6.66-3 6.65-6.69 0-3.69-3-6.65-6.69-6.65h-.2c-3.69 0-6.67 2.99-6.67 6.67 0 3.69 2.98 6.67 6.67 6.67h.24Z"
            />
            <path
              fill="url(#o)"
              d="M313.51 26.68h-.2c-3.69 0-6.67 2.99-6.67 6.67 0 3.69 2.98 6.67 6.67 6.67h.23c3.68 0 6.66-3 6.65-6.69 0-3.69-3-6.65-6.69-6.65h.01Z"
            />
            <path
              fill="url(#p)"
              d="M327.07 53.37h-.2c-3.68 0-6.67 2.99-6.67 6.67 0 3.69 2.99 6.67 6.67 6.67h.23c3.68 0 6.66-3 6.65-6.69 0-3.69-3-6.65-6.69-6.65h.01Z"
            />
            <path
              fill="url(#q)"
              d="M293.52 33.34c0-3.69-3-6.65-6.69-6.65h-.2c-3.69 0-6.67 2.99-6.67 6.67 0 3.69 2.98 6.67 6.67 6.67h.23c3.68 0 6.66-3 6.66-6.69Z"
            />
            <path
              fill="url(#r)"
              d="M80.27 33.34c0-3.69-3-6.65-6.69-6.65h-.2c-3.69 0-6.67 2.99-6.67 6.67 0 3.69 2.99 6.67 6.67 6.67h.23c3.68 0 6.66-3 6.65-6.69h.01Z"
            />
            <path
              fill="url(#s)"
              d="M347.1 33.34c0-3.69-3-6.65-6.69-6.65h-.2c-3.69 0-6.67 2.99-6.67 6.67 0 3.69 2.98 6.67 6.67 6.67h.23c3.68 0 6.66-3 6.65-6.69h.01Z"
            />
            <path
              fill="url(#t)"
              d="M387.13 60.02c0-3.69-3-6.65-6.69-6.65h-.2c-3.69 0-6.67 2.99-6.67 6.67 0 3.69 2.98 6.67 6.67 6.67h.23c3.68 0 6.66-3 6.65-6.69h.01Z"
            />
            <path
              fill="url(#u)"
              d="M353.76 53.37h-.2c-3.68 0-6.67 2.99-6.67 6.67 0 3.69 2.99 6.67 6.67 6.67h.23c3.68 0 6.66-3 6.65-6.69 0-3.69-3-6.65-6.69-6.65h.01Z"
            />
            <path
              fill="url(#v)"
              d="M300.17 53.37h-.2c-3.68 0-6.67 2.99-6.67 6.67 0 3.69 2.98 6.67 6.67 6.67h.23c2.99 0 5.52-1.99 6.36-4.7 0-.01 0-.03.01-.04.06-.2.11-.39.15-.6.02-.12.04-.24.05-.36.01-.1.04-.2.05-.3.01-.13.01-.26.02-.4 0-.1.01-.19.01-.28 0-3.69-3-6.65-6.69-6.65l.01-.01Z"
            />
            <path
              fill="url(#w)"
              d="M220.12 53.37h-.2c-3.69 0-6.67 2.99-6.67 6.67 0 3.69 2.98 6.67 6.67 6.67h.23c3.68 0 6.66-3 6.66-6.69 0-3.69-3-6.65-6.69-6.65Z"
            />
            <path
              fill="url(#x)"
              d="M213.47 33.34c0-3.69-3-6.65-6.69-6.65h-.2c-3.68 0-6.67 2.99-6.67 6.67 0 3.69 2.99 6.67 6.67 6.67h.23c3.68 0 6.66-3 6.65-6.69h.01Z"
            />
            <path
              fill="url(#y)"
              d="M240.15 33.34c0-3.69-3-6.65-6.69-6.65h-.2c-3.69 0-6.67 2.99-6.67 6.67 0 3.69 2.98 6.67 6.67 6.67h.23c3.68 0 6.66-3 6.65-6.69h.01Z"
            />
            <path
              fill="url(#z)"
              d="M246.81 53.37h-.2c-3.69 0-6.67 2.99-6.67 6.67 0 3.69 2.99 6.67 6.67 6.67h.23c3.68 0 6.66-3 6.65-6.69 0-3.69-3-6.65-6.69-6.65h.01Z"
            />
            <path
              fill="url(#A)"
              d="M273.49 53.37h-.2c-3.69 0-6.67 2.99-6.67 6.67 0 3.69 2.98 6.67 6.67 6.67h.23c3.68 0 6.66-3 6.65-6.69 0-3.69-3-6.65-6.69-6.65h.01Z"
            />
            <path
              fill="url(#B)"
              d="M266.83 33.34c0-3.69-3-6.65-6.69-6.65h-.2c-3.68 0-6.67 2.99-6.67 6.67 0 3.69 2.99 6.67 6.67 6.67h.23c3.68 0 6.66-3 6.65-6.69h.01Z"
            />
            <defs>
              <linearGradient
                id="b"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="c"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="d"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="e"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="f"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="g"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="h"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="i"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="j"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="k"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="l"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="m"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="n"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="o"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="p"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="q"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="r"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="s"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="t"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="u"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="v"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="w"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="x"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="y"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="z"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="A"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
              <linearGradient
                id="B"
                x1="0"
                x2="314"
                y1="33.355"
                y2="33.355"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset=".476" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
            </defs>
          </svg>
          <svg
            aria-hidden="true"
            className="absolute z-[1] right-[46px] bottom-[97px]"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M7.0352 1.68745e-05L7.23527 1.68658e-05C10.9209 1.67047e-05 13.9145 2.96857 13.9229 6.65423C13.9312 10.3399 10.946 13.3335 7.26866 13.3418L7.0352 13.3418C3.34954 13.3418 0.364258 10.3566 0.364258 6.67091C0.364257 2.98524 3.34954 1.70356e-05 7.0352 1.68745e-05Z"
              fill="url(#paint0_linear_6132_12365)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_6132_12365"
                x1="-253"
                y1="-33.3524"
                x2="61"
                y2="-33.3524"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7D003D" />
                <stop offset="0.476296" stopColor="#E92184" />
                <stop offset="1" stopColor="#1F9E82" />
              </linearGradient>
            </defs>
          </svg>
          <VideoButton {...videoButton} />
        </>
      )}
    </div>
  )
}
