import { IClassName } from '~@Types/components/IClassName'

export default function ExpertiseSymbol({ className }: IClassName) {
  return (
    <svg
      className={className}
      width="216"
      height="216"
      viewBox="0 0 216 216"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M169.714 70.9715C169.714 74.3799 166.951 77.1429 163.542 77.1429C160.134 77.1429 157.371 74.3799 157.371 70.9715C157.371 67.5631 160.134 64.8 163.542 64.8C166.951 64.8 169.714 67.5631 169.714 70.9715ZM145.028 95.6571C148.436 95.6571 151.199 92.894 151.199 89.4856C151.199 86.0772 148.436 83.3142 145.028 83.3142C141.62 83.3142 138.857 86.0772 138.857 89.4856C138.857 92.894 141.62 95.6571 145.028 95.6571ZM52.4566 114.171C55.865 114.171 58.628 111.408 58.628 108C58.628 104.592 55.865 101.829 52.4566 101.829C49.0482 101.829 46.2852 104.592 46.2852 108C46.2852 111.408 49.0482 114.171 52.4566 114.171ZM70.971 132.686C74.3793 132.686 77.1424 129.923 77.1424 126.514C77.1424 123.106 74.3793 120.343 70.971 120.343C67.5626 120.343 64.7995 123.106 64.7995 126.514C64.7995 129.923 67.5626 132.686 70.971 132.686ZM95.6567 145.029C95.6567 148.437 92.8937 151.2 89.4853 151.2C86.0769 151.2 83.3139 148.437 83.3139 145.029C83.3139 141.62 86.0769 138.857 89.4853 138.857C92.8937 138.857 95.6567 141.62 95.6567 145.029ZM107.999 132.686C111.408 132.686 114.171 129.923 114.171 126.514C114.171 123.106 111.408 120.343 107.999 120.343C104.591 120.343 101.828 123.106 101.828 126.514C101.828 129.923 104.591 132.686 107.999 132.686ZM132.685 108C132.685 111.408 129.922 114.171 126.514 114.171C123.105 114.171 120.342 111.408 120.342 108C120.342 104.592 123.105 101.829 126.514 101.829C129.922 101.829 132.685 104.592 132.685 108Z"
        fill="url(#paint0_linear_6643_3652)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6643_3652"
          x1="107.999"
          y1="151.2"
          x2="107.999"
          y2="64.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D003D" />
          <stop offset="0.476296" stopColor="#E92184" />
          <stop offset="1" stopColor="#1F9E82" />
        </linearGradient>
      </defs>
    </svg>
  )
}
