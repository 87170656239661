const BubbleShape = ({ className }: { className: string }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="288"
      height="119"
      viewBox="0 0 288 119"
      fill="none"
    >
      <path
        d="M191.832 13.2595L192.031 13.2595C195.694 13.2595 198.669 10.3093 198.677 6.64635C198.686 2.9834 195.719 8.55514e-06 192.064 8.39539e-06L191.832 8.38525e-06C188.169 8.22514e-06 185.202 2.96683 185.202 6.62977C185.202 10.2927 188.169 13.2595 191.832 13.2595Z"
        fill="url(#paint0_linear_6822_11071)"
      />
      <path
        d="M218.352 13.2595L218.551 13.2595C222.214 13.2595 225.189 10.3093 225.197 6.64635C225.205 2.9834 222.238 0.00829692 218.584 9.5546e-06L218.352 9.54446e-06C214.689 9.38435e-06 211.722 2.96683 211.722 6.62977C211.722 10.2927 214.689 13.2595 218.352 13.2595Z"
        fill="url(#paint1_linear_6822_11071)"
      />
      <path
        d="M245.085 13.2595L245.284 13.2595C248.947 13.2595 251.922 10.3093 251.931 6.64635C251.939 2.9834 248.972 0.00829809 245.317 1.07232e-05L245.085 1.0713e-05C241.422 1.05529e-05 238.456 2.96683 238.456 6.62977C238.456 10.2927 241.422 13.2595 245.085 13.2595Z"
        fill="url(#paint2_linear_6822_11071)"
      />
      <path
        d="M271.605 13.2595L271.804 13.2595C275.467 13.2595 278.442 10.3093 278.45 6.64635C278.458 2.98341 275.492 0.00829925 271.837 1.18824e-05L271.605 1.18722e-05C267.942 1.17121e-05 264.975 2.96683 264.975 6.62978C264.975 10.2927 267.942 13.2595 271.605 13.2595Z"
        fill="url(#paint3_linear_6822_11071)"
      />
      <path
        d="M298.123 13.2595L298.322 13.2595C301.985 13.2595 304.96 10.3093 304.968 6.64635C304.977 2.98341 302.01 0.0083004 298.355 1.30415e-05L298.123 1.30314e-05C294.46 1.28713e-05 291.493 2.96683 291.493 6.62978C291.493 10.2927 294.46 13.2595 298.123 13.2595Z"
        fill="url(#paint4_linear_6822_11071)"
      />
      <path
        d="M324.643 13.2595L324.842 13.2595C328.505 13.2595 331.48 10.3093 331.488 6.64635C331.496 2.98341 328.529 1.43605e-05 324.875 1.42007e-05L324.643 1.41906e-05C320.98 1.40305e-05 318.013 2.96683 318.013 6.62978C318.013 10.2927 320.98 13.2595 324.643 13.2595Z"
        fill="url(#paint5_linear_6822_11071)"
      />
      <path
        d="M351.162 13.2595L351.361 13.2595C355.024 13.2595 357.999 10.3093 358.008 6.64635C358.016 2.98341 355.049 1.55197e-05 351.394 1.53599e-05L351.162 1.53498e-05C347.499 1.51897e-05 344.533 2.96683 344.533 6.62978C344.533 10.2927 347.499 13.2595 351.162 13.2595Z"
        fill="url(#paint6_linear_6822_11071)"
      />
      <path
        d="M377.681 13.2595L377.88 13.2595C381.542 13.2595 384.518 10.3093 384.526 6.64636C384.534 2.98341 381.567 1.66788e-05 377.913 1.65191e-05L377.681 1.65089e-05C374.018 1.63488e-05 371.051 2.96684 371.051 6.62978C371.051 10.2927 374.018 13.2595 377.681 13.2595Z"
        fill="url(#paint7_linear_6822_11071)"
      />
      <path
        d="M404.416 13.2595L404.614 13.2595C408.277 13.2595 411.253 10.3093 411.261 6.64636C411.269 2.98341 408.302 1.78475e-05 404.648 1.76877e-05L404.416 1.76776e-05C400.753 1.75175e-05 397.786 2.96684 397.786 6.62978C397.786 10.2927 400.753 13.2595 404.416 13.2595Z"
        fill="url(#paint8_linear_6822_11071)"
      />
      <path
        d="M430.934 13.2595L431.133 13.2595C434.796 13.2595 437.771 10.3093 437.779 6.64636C437.787 2.98341 434.82 0.00830621 431.166 1.88469e-05L430.934 1.88367e-05C427.271 1.86766e-05 424.304 2.96684 424.304 6.62978C424.304 10.2927 427.271 13.2595 430.934 13.2595Z"
        fill="url(#paint9_linear_6822_11071)"
      />
      <path
        d="M457.453 13.2595L457.652 13.2595C461.315 13.2595 464.29 10.3093 464.299 6.64636C464.307 2.98341 461.34 0.00830737 457.685 2.00061e-05L457.453 1.99959e-05C453.79 1.98358e-05 450.824 2.96684 450.824 6.62978C450.824 10.2927 453.79 13.2595 457.453 13.2595Z"
        fill="url(#paint10_linear_6822_11071)"
      />
      <path
        d="M205.091 39.782L205.289 39.782C208.952 39.782 211.928 36.8317 211.936 33.1688C211.944 29.5058 208.977 26.5307 205.323 26.5224L205.091 26.5224C201.428 26.5224 198.461 29.4893 198.461 33.1522C198.461 36.8151 201.428 39.782 205.091 39.782Z"
        fill="url(#paint11_linear_6822_11071)"
      />
      <path
        d="M231.826 39.782L232.024 39.782C235.687 39.782 238.662 36.8317 238.671 33.1688C238.679 29.5058 235.712 26.5307 232.058 26.5224L231.826 26.5224C228.163 26.5224 225.196 29.4893 225.196 33.1522C225.196 36.8151 228.163 39.782 231.826 39.782Z"
        fill="url(#paint12_linear_6822_11071)"
      />
      <path
        d="M258.344 39.782L258.543 39.782C262.206 39.782 265.181 36.8317 265.189 33.1688C265.197 29.5058 262.23 26.5307 258.576 26.5224L258.344 26.5224C254.681 26.5224 251.714 29.4893 251.714 33.1522C251.714 36.8152 254.681 39.782 258.344 39.782Z"
        fill="url(#paint13_linear_6822_11071)"
      />
      <path
        d="M284.863 39.782L285.062 39.782C288.725 39.782 291.7 36.8317 291.709 33.1688C291.717 29.5058 288.75 26.5307 285.095 26.5224L284.863 26.5224C281.2 26.5224 278.234 29.4893 278.234 33.1522C278.234 36.8152 281.2 39.782 284.863 39.782Z"
        fill="url(#paint14_linear_6822_11071)"
      />
      <path
        d="M311.382 39.782L311.58 39.782C315.243 39.782 318.219 36.8317 318.227 33.1688C318.235 29.5058 315.268 26.5307 311.614 26.5224L311.382 26.5224C307.719 26.5224 304.752 29.4893 304.752 33.1522C304.752 36.8152 307.719 39.782 311.382 39.782Z"
        fill="url(#paint15_linear_6822_11071)"
      />
      <path
        d="M337.901 39.782L338.1 39.782C341.763 39.782 344.738 36.8317 344.746 33.1688C344.755 29.5058 341.788 26.5307 338.133 26.5224L337.901 26.5224C334.238 26.5224 331.271 29.4893 331.271 33.1522C331.271 36.8152 334.238 39.782 337.901 39.782Z"
        fill="url(#paint16_linear_6822_11071)"
      />
      <path
        d="M364.421 39.782L364.62 39.782C368.283 39.782 371.258 36.8317 371.266 33.1688C371.274 29.5058 368.307 26.5307 364.653 26.5224L364.421 26.5224C360.758 26.5224 357.791 29.4893 357.791 33.1522C357.791 36.8152 360.758 39.782 364.421 39.782Z"
        fill="url(#paint17_linear_6822_11071)"
      />
      <path
        d="M391.154 39.782L391.353 39.782C395.016 39.782 397.991 36.8317 398 33.1688C398.008 29.5058 395.041 26.5307 391.386 26.5224L391.154 26.5224C387.491 26.5224 384.525 29.4893 384.525 33.1522C384.525 36.8152 387.491 39.782 391.154 39.782Z"
        fill="url(#paint18_linear_6822_11071)"
      />
      <path
        d="M139.008 66.3016L139.207 66.3016C142.87 66.3016 145.845 63.3513 145.853 59.6884C145.862 56.0255 142.895 53.0503 139.24 53.0421L139.008 53.0421C135.345 53.0421 132.379 56.0089 132.379 59.6718C132.379 63.3348 135.345 66.3016 139.008 66.3016Z"
        fill="url(#paint19_linear_6822_11071)"
      />
      <path
        d="M165.526 66.3016L165.725 66.3016C169.388 66.3016 172.363 63.3513 172.372 59.6884C172.38 56.0255 169.413 53.0503 165.759 53.0421L165.526 53.0421C161.864 53.0421 158.897 56.0089 158.897 59.6718C158.897 63.3348 161.864 66.3016 165.526 66.3016Z"
        fill="url(#paint20_linear_6822_11071)"
      />
      <path
        d="M192.046 66.3016L192.245 66.3016C195.908 66.3016 198.883 63.3513 198.891 59.6884C198.9 56.0255 195.933 53.0503 192.278 53.0421L192.046 53.0421C188.383 53.0421 185.416 56.0089 185.416 59.6718C185.416 63.3348 188.383 66.3016 192.046 66.3016Z"
        fill="url(#paint21_linear_6822_11071)"
      />
      <path
        d="M218.781 66.3016L218.98 66.3016C222.643 66.3016 225.618 63.3513 225.626 59.6884C225.635 56.0255 222.668 53.0503 219.013 53.0421L218.781 53.0421C215.118 53.0421 212.151 56.0089 212.151 59.6718C212.151 63.3348 215.118 66.3016 218.781 66.3016Z"
        fill="url(#paint22_linear_6822_11071)"
      />
      <path
        d="M245.299 66.3016L245.498 66.3016C249.161 66.3016 252.136 63.3513 252.145 59.6884C252.153 56.0255 249.186 53.0503 245.531 53.0421L245.299 53.0421C241.636 53.0421 238.67 56.0089 238.67 59.6718C238.67 63.3348 241.636 66.3016 245.299 66.3016Z"
        fill="url(#paint23_linear_6822_11071)"
      />
      <path
        d="M271.819 66.3016L272.018 66.3016C275.681 66.3016 278.656 63.3513 278.664 59.6884C278.672 56.0255 275.706 53.0503 272.051 53.0421L271.819 53.0421C268.156 53.0421 265.189 56.0089 265.189 59.6718C265.189 63.3348 268.156 66.3016 271.819 66.3016Z"
        fill="url(#paint24_linear_6822_11071)"
      />
      <path
        d="M59.8815 66.3016L60.0804 66.3016C63.7434 66.3016 66.7185 63.3513 66.7268 59.6884C66.735 56.0254 63.7682 53.0503 60.1136 53.0421L59.8815 53.0421C56.2186 53.0421 53.2518 56.0089 53.2518 59.6718C53.2518 63.3348 56.2186 66.3016 59.8815 66.3016Z"
        fill="url(#paint25_linear_6822_11071)"
      />
      <path
        d="M86.4011 66.3016L86.6 66.3016C90.263 66.3016 93.2381 63.3513 93.2464 59.6884C93.2547 56.0254 90.2878 53.0503 86.6332 53.0421L86.4011 53.0421C82.7382 53.0421 79.7714 56.0089 79.7714 59.6718C79.7714 63.3348 82.7382 66.3016 86.4011 66.3016Z"
        fill="url(#paint26_linear_6822_11071)"
      />
      <path
        d="M112.919 66.3016L113.118 66.3016C116.781 66.3016 119.756 63.3513 119.765 59.6884C119.773 56.0254 116.806 53.0503 113.151 53.0421L112.919 53.0421C109.256 53.0421 106.29 56.0089 106.29 59.6718C106.29 63.3348 109.256 66.3016 112.919 66.3016Z"
        fill="url(#paint27_linear_6822_11071)"
      />
      <path
        d="M298.337 66.3016L298.536 66.3016C302.199 66.3016 305.174 63.3513 305.182 59.6884C305.191 56.0255 302.224 53.0503 298.569 53.0421L298.337 53.0421C294.674 53.0421 291.707 56.0089 291.707 59.6718C291.707 63.3348 294.674 66.3016 298.337 66.3016Z"
        fill="url(#paint28_linear_6822_11071)"
      />
      <path
        d="M324.857 66.3016L325.056 66.3016C328.719 66.3016 331.694 63.3513 331.702 59.6884C331.71 56.0255 328.743 53.0503 325.089 53.0421L324.857 53.0421C321.194 53.0421 318.227 56.0089 318.227 59.6718C318.227 63.3348 321.194 66.3016 324.857 66.3016Z"
        fill="url(#paint29_linear_6822_11071)"
      />
      <path
        d="M152.053 92.8184L152.252 92.8184C155.915 92.8184 158.89 89.8681 158.898 86.2052C158.906 82.5423 155.939 79.5671 152.285 79.5589L152.053 79.5589C148.39 79.5589 145.423 82.5257 145.423 86.1886C145.423 89.8516 148.39 92.8184 152.053 92.8184Z"
        fill="url(#paint30_linear_6822_11071)"
      />
      <path
        d="M178.572 92.8184L178.771 92.8184C182.434 92.8184 185.409 89.8681 185.418 86.2052C185.426 82.5423 182.459 79.5671 178.804 79.5589L178.572 79.5589C174.909 79.5589 171.943 82.5257 171.943 86.1886C171.943 89.8516 174.909 92.8184 178.572 92.8184Z"
        fill="url(#paint31_linear_6822_11071)"
      />
      <path
        d="M205.091 92.8184L205.289 92.8184C208.952 92.8184 211.928 89.8681 211.936 86.2052C211.944 82.5423 208.977 79.5671 205.323 79.5589L205.091 79.5589C201.428 79.5589 198.461 82.5257 198.461 86.1886C198.461 89.8516 201.428 92.8184 205.091 92.8184Z"
        fill="url(#paint32_linear_6822_11071)"
      />
      <path
        d="M231.826 92.8184L232.024 92.8184C235.687 92.8184 238.662 89.8681 238.671 86.2052C238.679 82.5423 235.712 79.5671 232.058 79.5589L231.826 79.5589C228.163 79.5589 225.196 82.5257 225.196 86.1886C225.196 89.8516 228.163 92.8184 231.826 92.8184Z"
        fill="url(#paint33_linear_6822_11071)"
      />
      <path
        d="M258.345 92.8184L258.544 92.8184C262.207 92.8184 265.182 89.8681 265.19 86.2052C265.199 82.5423 262.232 79.5672 258.577 79.5589L258.345 79.5589C254.682 79.5589 251.715 82.5257 251.715 86.1886C251.715 89.8516 254.682 92.8184 258.345 92.8184Z"
        fill="url(#paint34_linear_6822_11071)"
      />
      <path
        d="M284.863 92.8184L285.062 92.8184C288.725 92.8184 291.7 89.8681 291.709 86.2052C291.717 82.5423 288.75 79.5672 285.095 79.5589L284.863 79.5589C281.2 79.5589 278.234 82.5257 278.234 86.1886C278.234 89.8516 281.2 92.8184 284.863 92.8184Z"
        fill="url(#paint35_linear_6822_11071)"
      />
      <path
        d="M99.4456 92.8184L99.6445 92.8184C103.307 92.8184 106.283 89.8681 106.291 86.2052C106.299 82.5422 103.332 79.5671 99.6776 79.5589L99.4456 79.5589C95.7827 79.5589 92.8158 82.5257 92.8158 86.1886C92.8158 89.8516 95.7827 92.8184 99.4456 92.8184Z"
        fill="url(#paint36_linear_6822_11071)"
      />
      <path
        d="M125.965 92.8184L126.164 92.8184C129.827 92.8184 132.802 89.8681 132.81 86.2052C132.819 82.5422 129.852 79.5671 126.197 79.5589L125.965 79.5589C122.302 79.5589 119.335 82.5257 119.335 86.1886C119.335 89.8516 122.302 92.8184 125.965 92.8184Z"
        fill="url(#paint37_linear_6822_11071)"
      />
      <path
        d="M311.383 92.8184L311.582 92.8184C315.245 92.8184 318.22 89.8681 318.228 86.2052C318.237 82.5423 315.27 79.5672 311.615 79.5589L311.383 79.5589C307.72 79.5589 304.753 82.5257 304.753 86.1886C304.753 89.8516 307.72 92.8184 311.383 92.8184Z"
        fill="url(#paint38_linear_6822_11071)"
      />
      <path
        d="M337.901 92.8184L338.1 92.8184C341.763 92.8184 344.738 89.8681 344.746 86.2052C344.755 82.5423 341.788 79.5672 338.133 79.5589L337.901 79.5589C334.238 79.5589 331.271 82.5257 331.271 86.1886C331.271 89.8516 334.238 92.8184 337.901 92.8184Z"
        fill="url(#paint39_linear_6822_11071)"
      />
      <path
        d="M165.529 119.338L165.728 119.338C169.391 119.338 172.366 116.388 172.375 112.725C172.383 109.062 169.416 106.087 165.761 106.078L165.529 106.078C161.866 106.078 158.9 109.045 158.9 112.708C158.9 116.371 161.866 119.338 165.529 119.338Z"
        fill="url(#paint40_linear_6822_11071)"
      />
      <path
        d="M192.048 119.338L192.246 119.338C195.909 119.338 198.884 116.388 198.893 112.725C198.901 109.062 195.934 106.087 192.28 106.078L192.048 106.078C188.385 106.078 185.418 109.045 185.418 112.708C185.418 116.371 188.385 119.338 192.048 119.338Z"
        fill="url(#paint41_linear_6822_11071)"
      />
      <path
        d="M218.567 119.338L218.766 119.338C222.429 119.338 225.404 116.388 225.412 112.725C225.421 109.062 222.454 106.087 218.799 106.078L218.567 106.078C214.904 106.078 211.937 109.045 211.937 112.708C211.937 116.371 214.904 119.338 218.567 119.338Z"
        fill="url(#paint42_linear_6822_11071)"
      />
      <path
        d="M245.301 119.338L245.5 119.338C249.163 119.338 252.138 116.388 252.146 112.725C252.154 109.062 249.187 106.087 245.533 106.078L245.301 106.078C241.638 106.078 238.671 109.045 238.671 112.708C238.671 116.371 241.638 119.338 245.301 119.338Z"
        fill="url(#paint43_linear_6822_11071)"
      />
      <path
        d="M271.82 119.338L272.019 119.338C275.682 119.338 278.657 116.388 278.666 112.725C278.674 109.062 275.707 106.087 272.052 106.078L271.82 106.078C268.157 106.078 265.191 109.045 265.191 112.708C265.191 116.371 268.157 119.338 271.82 119.338Z"
        fill="url(#paint44_linear_6822_11071)"
      />
      <path
        d="M6.62976 119.338L6.82865 119.338C10.4916 119.338 13.4667 116.388 13.475 112.725C13.4833 109.062 10.5165 106.087 6.8618 106.078L6.62976 106.078C2.96681 106.078 -4.76652e-06 109.045 -4.92663e-06 112.708C-5.08675e-06 116.371 2.96681 119.338 6.62976 119.338Z"
        fill="url(#paint45_linear_6822_11071)"
      />
      <path
        d="M33.1494 119.338L33.3483 119.338C37.0112 119.338 39.9863 116.388 39.9946 112.725C40.0029 109.062 37.0361 106.087 33.3814 106.078L33.1494 106.078C29.4864 106.078 26.5196 109.045 26.5196 112.708C26.5196 116.371 29.4864 119.338 33.1494 119.338Z"
        fill="url(#paint46_linear_6822_11071)"
      />
      <path
        d="M59.6676 119.338L59.8665 119.338C63.5294 119.338 66.5045 116.388 66.5128 112.725C66.5211 109.062 63.5543 106.087 59.8996 106.078L59.6676 106.078C56.0046 106.078 53.0378 109.045 53.0378 112.708C53.0378 116.371 56.0046 119.338 59.6676 119.338Z"
        fill="url(#paint47_linear_6822_11071)"
      />
      <path
        d="M86.4025 119.338L86.6014 119.338C90.2644 119.338 93.2395 116.388 93.2478 112.725C93.2561 109.062 90.2892 106.087 86.6346 106.078L86.4025 106.078C82.7396 106.078 79.7728 109.045 79.7728 112.708C79.7728 116.371 82.7396 119.338 86.4025 119.338Z"
        fill="url(#paint48_linear_6822_11071)"
      />
      <path
        d="M112.921 119.338L113.12 119.338C116.783 119.338 119.758 116.388 119.766 112.725C119.774 109.062 116.807 106.087 113.153 106.078L112.921 106.078C109.258 106.078 106.291 109.045 106.291 112.708C106.291 116.371 109.258 119.338 112.921 119.338Z"
        fill="url(#paint49_linear_6822_11071)"
      />
      <path
        d="M139.44 119.338L139.639 119.338C143.302 119.338 146.277 116.388 146.286 112.725C146.294 109.062 143.327 106.087 139.672 106.078L139.44 106.078C135.777 106.078 132.811 109.045 132.811 112.708C132.811 116.371 135.777 119.338 139.44 119.338Z"
        fill="url(#paint50_linear_6822_11071)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
        <linearGradient
          id="paint50_linear_6822_11071"
          x1="291"
          y1="59.669"
          x2="8.37188e-07"
          y2="59.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F9E82" />
          <stop offset="0.523704" stopColor="#E92184" />
          <stop offset="1" stopColor="#7D003D" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default BubbleShape
