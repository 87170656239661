import { IClassName } from '~@Types/components/IClassName'

export default function SynergySymbol({ className }: IClassName) {
  return (
    <svg
      className={className}
      width="216"
      height="216"
      viewBox="0 0 216 216"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108 64.7999C111.408 64.7999 114.171 62.0368 114.171 58.6285C114.171 55.2201 111.408 52.457 108 52.457C104.592 52.457 101.828 55.2201 101.828 58.6285C101.828 62.0368 104.592 64.7999 108 64.7999ZM114.171 83.3142C114.171 86.7226 111.408 89.4856 108 89.4856C104.592 89.4856 101.828 86.7226 101.828 83.3142C101.828 79.9058 104.592 77.1427 108 77.1427C111.408 77.1427 114.171 79.9058 114.171 83.3142ZM108 114.171C111.408 114.171 114.171 111.408 114.171 108C114.171 104.592 111.408 101.828 108 101.828C104.592 101.828 101.828 104.592 101.828 108C101.828 111.408 104.592 114.171 108 114.171ZM108 138.857C111.408 138.857 114.171 136.094 114.171 132.686C114.171 129.277 111.408 126.514 108 126.514C104.592 126.514 101.828 129.277 101.828 132.686C101.828 136.094 104.592 138.857 108 138.857ZM114.171 157.371C114.171 160.78 111.408 163.543 108 163.543C104.592 163.543 101.828 160.78 101.828 157.371C101.828 153.963 104.592 151.2 108 151.2C111.408 151.2 114.171 153.963 114.171 157.371ZM132.686 114.171C136.094 114.171 138.857 111.408 138.857 108C138.857 104.592 136.094 101.828 132.686 101.828C129.277 101.828 126.514 104.592 126.514 108C126.514 111.408 129.277 114.171 132.686 114.171ZM163.543 108C163.543 111.408 160.78 114.171 157.371 114.171C153.963 114.171 151.2 111.408 151.2 108C151.2 104.592 153.963 101.828 157.371 101.828C160.78 101.828 163.543 104.592 163.543 108ZM58.6285 114.171C62.0368 114.171 64.7999 111.408 64.7999 108C64.7999 104.592 62.0368 101.828 58.6285 101.828C55.2201 101.828 52.457 104.592 52.457 108C52.457 111.408 55.2201 114.171 58.6285 114.171ZM89.4856 108C89.4856 111.408 86.7226 114.171 83.3142 114.171C79.9058 114.171 77.1427 111.408 77.1427 108C77.1427 104.592 79.9058 101.828 83.3142 101.828C86.7226 101.828 89.4856 104.592 89.4856 108Z"
        fill="url(#paint0_linear_6851_1053)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6851_1053"
          x1="52.457"
          y1="108"
          x2="163.543"
          y2="108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D003D" />
          <stop offset="0.476296" stopColor="#E92184" />
          <stop offset="1" stopColor="#1F9E82" />
        </linearGradient>
      </defs>
    </svg>
  )
}
